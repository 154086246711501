.nav-item {
    position: relative;
    padding-bottom: 2px; 
    color: #FDFDFD;
}

.nav-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FDFDFD; 
    transition: width 150ms ease-in;
}

.nav-item:hover::after {
    width: 100%;
}

@keyframes moveArrow {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-2px);
    }
  }
  
  .hover\:animate-moveArrow:hover .move-arrow {
    animation: moveArrow 0.5s ease-in-out infinite;
  }
